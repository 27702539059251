import React from 'react';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import Background from './background.svg'; // Ensure the path is correct
import './Landing.css';

const Landing = () => {
  const { scrollY } = useViewportScroll();
  const y1 = useTransform(scrollY, [0, 300], [0, -50]);
  const y2 = useTransform(scrollY, [0, 300], [0, 50]);

  return (
    <div className="landing">
      <motion.div className="background" style={{ y: y1 }} />
      <motion.div className="right-image" style={{ y: y2 }} />
      <motion.div 
        className="right-content"
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <h1>A Y U S H A R K S</h1>
        <p>
          The AYUSH startup portal is a unique online platform designed for all
          stakeholders in the Indian startup ecosystem related to Ayurveda, Yoga,
          Unani, Siddha, and Homeopathy (AYUSH).
        </p>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          EXPLORE NOW
        </motion.button>
      </motion.div>
    </div>
  );
};

export default Landing;
