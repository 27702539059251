import React from 'react';
import { motion } from 'framer-motion';
import Fut1 from './fut1.png';
import Fut2 from './fut2.png';
import Fut3 from './fut3.png';
import Fut4 from './fut4.png';
import './Future.css';

const FutureSection = () => {
  return (
    <div className="future-section-container">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="future-heading">OUR FUTURE</div>
        <p className="future-info">STARTUP TOOLKITS AND FACILITIES</p>
      </motion.div>
      <div className="future-image-row">
        <motion.img
          src={Fut1}
          alt="Step 1"
          className="future-image"
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        />
        <motion.img
          src={Fut2}
          alt="Step 2"
          className="future-image"
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        />
        <motion.img
          src={Fut3}
          alt="Step 3"
          className="future-image"
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        />
        <motion.img
          src={Fut4}
          alt="Step 4"
          className="future-image"
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        />
      </div>
    </div>
  );
};

export default FutureSection;
