import React from 'react';
import { motion } from 'framer-motion';
import logo from './logo.svg';
import './Navbar.css';

const NavBar = () => {
  return (
    <motion.nav 
      className="nav"
      initial={{ y: -250 }}
      animate={{ y: 0 }}
      transition={{ delay: 0.2, type: 'spring', stiffness: 120 }}
    >
      <img src={logo} alt="Ayusharks Logo" className="logo" />
      <ul className="nav-links">
        <li><a href="#home">MORE FEATURE WOULD BE COMING SOON</a></li>

      </ul>
    </motion.nav>
  );
};

export default NavBar;
