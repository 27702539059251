import React from 'react';
import Navbar from './Navbar'; // Adjust the path as needed
import step1 from './step1.svg';
import step2 from './step2.svg';
import step3 from './step3.svg';
import { motion } from 'framer-motion';
import './App.css'; // Import the CSS file
import Landing from './Landing.jsx'
import Future from './Future.jsx';

const App = () => {
  return (
    <div className="app">
      <Navbar />
      <Landing/>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="heading">HOW IT WORKS</div>
        <p className="info">GROW UP YOUR CONNECTIONS</p>
      </motion.div>
      <div className="image-row">
        <motion.img
          src={step1}
          alt="Step 1"
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        />
        <motion.img
          src={step2}
          alt="Step 2"
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        />
        <motion.img
          src={step3}
          alt="Step 3"
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        />
      </div>
      <Future/>
    </div>
  );
};

export default App;
